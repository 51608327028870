<template>
  <v-container>
    <v-card class="mx-auto my-12" max-width="400">
      <v-card-title class="headline">Reset Password</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitResetPassword">
          <v-text-field
            v-model="password"
            label="New Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required, rules.min]"
            required
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            label="Confirm New Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.required, rules.match]"
            required
          ></v-text-field>
          <v-btn type="submit" :disabled="!isFormValid" color="primary">Reset Password</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      showPassword: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        match: v => v === this.password || 'Passwords must match'
      }
    };
  },
  computed: {
    isFormValid() {
      return this.password && this.confirmPassword && 
             this.password === this.confirmPassword &&
             this.password.length >= 8;
    },
  },
  created() {
    if (!this.token && !this.$route.query.token) {
      this.showSnackbar('Invalid or missing reset token. Please request a new password reset link.', 'error');
      this.$router.push('/');
    } else if (!this.token) {
      // If token is not passed as a prop, get it from the URL
      this.$router.replace({ query: { ...this.$route.query, token: this.$route.query.token }});
    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    async submitResetPassword() {
      if (!this.isFormValid) {
        this.showSnackbar('Please make sure your passwords match and meet the requirements.', 'error');
        return;
      }
      try {
        await this.resetPassword({
          token: this.token || this.$route.query.token,
          newPassword: this.password
        });
        this.showSnackbar('Password reset successful', 'success');
        setTimeout(() => this.$router.push('/'), 2000);
      } catch (error) {
        console.error('Error resetting password', error);
        this.showSnackbar(error.message || 'Error resetting password. Please try again or request a new reset link.', 'error');
      }
    },
    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
};
</script>